<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path d="M33 18.8L15 8.42004" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M42 32V16C41.9993 15.2986 41.8141 14.6097 41.4631 14.0024C41.112 13.3951 40.6075 12.8908 40 12.54L26 4.54005C25.3919 4.18898 24.7021 4.00415 24 4.00415C23.2979 4.00415 22.6081 4.18898 22 4.54005L8 12.54C7.39253 12.8908 6.88796 13.3951 6.53692 14.0024C6.18589 14.6097 6.00072 15.2986 6 16V32C6.00072 32.7015 6.18589 33.3904 6.53692 33.9977C6.88796 34.605 7.39253 35.1093 8 35.46L22 43.46C22.6081 43.8111 23.2979 43.9959 24 43.9959C24.7021 43.9959 25.3919 43.8111 26 43.46L40 35.46C40.6075 35.1093 41.112 34.605 41.4631 33.9977C41.8141 33.3904 41.9993 32.7015 42 32Z"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.53906 13.92L23.9991 24.02L41.4591 13.92" stroke-width="1.6" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M24 44.16V24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'BoxIcon'
}
</script>
